import React, { Component } from "react";
import "./style.css";
import { Button, TextField, Paper } from "@material-ui/core";
import { Badge, Card, Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

//API handling components
import { API_URL } from "./../../global";
const axios = require("axios");

export class expenses extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showDeleteModal: false,
            description: "",
            amount: "",
            expenseData: [],
            activeExpenseId: null,
        };
    }
    getExpenseData() {
        const query = `SELECT *  from expenses order by id desc;`;
        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        axios
            .post(API_URL, data)
            .then((res) => {
                this.setState({ expenseData: res.data });
                this.initializeDataTable();
            })
            .catch((err) => {
                console.log(err);
                toast("failed to fetch");
            });
    }
    componentDidMount() {
        this.getExpenseData();
    }


    deleteRecord(id) {
        const query = `DELETE FROM expenses WHERE id = ${id};`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
          .post(API_URL, data)
          .then((res) => {
            console.log("deleted data: ", res.data);
            console.log("record deleted successfully");
            toast.error("Record deleted successfully");
            window.location.reload();
          })
          .catch((err) => {
            console.log("record delete error: ", err);
          });
      }

    handleSubmit(e, state) {
        e.preventDefault();
        const { description, amount } = state;
        const date = new Date();
        const query = `INSERT INTO expenses (description,date,amount) VALUES('${description}','${moment(
            date
        ).format()}',${amount});`;
        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        axios
            .post(API_URL, data)
            .then((res) => {
                toast("expense saved successfully");
                window.location.reload();
                this.getExpenseData();
            })
            .catch((err) => {
                console.log(err);
                toast("failed to save");
            });
    }

    deleteExpense(id) {
        const query = `DELETE from expenses WHERE id=${id};`;
        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        axios
            .post(API_URL, data)
            .then((res) => {
                toast.success("expense deleted successfully");
                this.getExpenseData();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    initializeDataTable() {
        const title = "Party data -" + moment().format("DD-MMMM-YYYY");

        $("#expenses_table").DataTable({
            destroy: true,
            keys: true,
            dom:
              "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
              "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
              "<'row'<'col-sm-12' tr>>" +
              "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
            buttons: [
              {
                extend: "csv",
                title,
                messageTop: `<h4 style='text-align:center'>${title}</h4>`,
                download: "open",
                exportOptions: {
                  columns: [0, 1, 2, 3, 4, 5],
                },
              },
              {
                extend: "print",
                title,
                messageTop: `<h4 style='text-align:center'>${title}</h4>`,
                download: "open",
                exportOptions: {
                  columns: [0, 1, 2, 3, 4, 5],
                },
              },
            ],
        });
    }

    render() {
        return (
            <div
                className="container-fluid border m-0 p-1 main"
                style={{ backgroundColor: "aliceblue" }}
            >
                <Card>
                    <Card.Body className="mt-0 pt-3">
                        <Card.Title>Manage Expenses</Card.Title>
                        <form
                            noValidate
                            autoComplete="off"
                            onSubmit={(e) => this.handleSubmit(e, this.state)}
                        >
                            <div className="mt-3">
                                <TextField
                                    id="amount"
                                    label="Amount"
                                    variant="outlined"
                                    type="number"
                                    size="small"
                                    value={this.state.amount}
                                    className="mr-3"
                                    onChange={(e) =>
                                        this.setState({
                                            amount: e.target.value,
                                        })
                                    }
                                />
                                <TextField
                                    id="description"
                                    label="Description"
                                    variant="outlined"
                                    size="small"
                                    value={this.state.description}
                                    className="mr-3"
                                    style={{ minWidth: "30vw" }}
                                    onChange={(e) =>
                                        this.setState({
                                            description: e.target.value,
                                        })
                                    }
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                >
                                    Add expense
                                </Button>
                            </div>
                        </form>
                    </Card.Body>
                </Card>
                <div
                    component={Paper}
                    style={{ width: "100%" }}
                    className="mt-2"
                >
                    <table
                        id="expenses_table"
                        className="display"
                        style={{ width: "100%" }}
                    >
                        <thead>
                            <tr>
                                <th align="center">Expense Id</th>
                                <th>Description</th>
                                <th>Amount</th>
                                <th>Date</th>
                                <th align="center">Option</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.expenseData.map((expense) => {
                                    return (
                                        <tr key={expense.id}>
                                            <td align="center">
                                                <Badge variant="primary">
                                                    {" "}
                                                    {expense.id}
                                                </Badge>
                                            </td>
                                            <td
                                                style={{
                                                    textTransform: "capitalize",
                                                }}
                                            >
                                                {expense.description}
                                            </td>
                                            <td>₹ {expense.amount}</td>
                                            <td>
                                                {moment(expense.date).format(
                                                    "D/M/YYYY h:mm A"
                                                )}
                                            </td>
                                            <td align="center">
                                                <Button
                                                    color="secondary"
                                                    variant="contained"
                                                    className="mt-1 mb-1"
                                                    onClick={(e) => {
                                                        var pin = window.prompt("Delete the item?")
                                                        if (pin == 280956) {
                                                            this.deleteRecord(expense.id);
                                                        }
                                                        else {
                                                            toast.error("Entered wrong pin");
                                                        }
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faTrash}
                                                    />
                                                </Button>
                                                
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <ToastContainer
                    position={toast.POSITION.TOP_RIGHT}
                    autoClose={3000}
                />
            </div>
        );
    }
}

export default expenses;
