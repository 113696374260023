import React, { Component } from "react";
import Box from "@material-ui/core/Box"

import {
    TextField,
    Button,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
} from "@material-ui/core";
// import { TabContext, TabList, TabPanel } from "@material-ui/lab";
// import { Row, Col, Card, Badge, Table as Tbl } from "react-bootstrap";
import "./style.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faSyncAlt } from "@fortawesome/free-solid-svg-icons";

import { ToastContainer, toast } from "react-toastify";

//API handling components
import { API_URL } from "./../../global";
const axios = require("axios");


const options = [
    { value: 'beverages', label: 'Beverages' },
    { value: 'snacks', label: 'Snacks' },
    { value: 'grocery', label: 'Grocery' },
    { value: 'bakery', label: 'Bakery' },

];

export class AddNewEntry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productId: 0,
            quantity: 0,
            amount: 0,
            paid: 0,
            expiry_date:'',
            pending: 0,
            products: null,
            selectedCompany: '',
            allProductCompany: [],


        };
    }

    fetchProducts = () => {
        const query = `SELECT id,name, quantity from products WHERE company = '${this.state.selectedCompany}';`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(API_URL, data)
            .then((res) => {
                let _res = res.data.map((item) => {
                    return [item.name, item.id];
                });
                this.setState({ products: _res });
            })
            .catch((err) => {
                console.log("product data fetch error: ", err);
            });
    };

    fetchAllProducts = () => {
        const query = "SELECT company from products";
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(API_URL, data)
            .then((res) => {
                let _res = res.data;
                const company = _res
                    .filter((item) => item.company !== null)
                    .map((item) => item.company)
                    .filter((company, index, self) => self.indexOf(company) === index);
                this.setState({ allProductCompany: company })

            })
            .catch((err) => {
                console.log("company data fetch error: ", err);
            });

    };

    handleCat = (event) => {
        this.setState({ selectedCompany: event.target.value });
    }


    updateProductCount(
        productId = this.state.productId,
        quantity = this.state.quantity,
        add = true
    ) {
        let query = ``;
        if (add === true) {
            query = `UPDATE products SET quantity = quantity + ${quantity} WHERE id=${productId};`;
        } else {
            query = `UPDATE products SET quantity = quantity - ${quantity} WHERE id=${productId};`;
        }

        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        axios
            .post(API_URL, data)
            .then((res) => {
                console.log("Product count updated successfully");
                setTimeout(() => {
                    this.props.refreshLedger();
                }, 2000);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    handleAddSubmit(e) {
        e.preventDefault();

        // calculate pending amount
        let pending = this.state.amount - this.state.paid;
        console.log(this.state.expiry_date)
        const query = `INSERT INTO stockledger(productId, quantity, amount, paid, pending, expiry_date, type) VALUES(${this.state.productId}, ${this.state.quantity}, ${this.state.amount}, ${this.state.paid}, ${pending},'${this.state.expiry_date}', 1);`;

        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        axios
            .post(API_URL, data)
            .then((res) => {
                this.updateProductCount();
                toast.success("Product Tracking record added successfully");
            })
            .catch((err) => {
                console.log(err);
            });
    }

    renderMenu() {
        if (this.state.products != null) {
            return this.state.products.map((product, index) => {
                return <MenuItem value={product[1]} key={index}>{product[0]}</MenuItem>;
            });
        }
    }

    componentDidMount() {
        this.fetchProducts();
        this.fetchAllProducts();

    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedCompany !== this.state.selectedCompany) {
            this.fetchProducts();
        }
    }

    render() {
        return (
            <div className="row">
                <form autoComplete="off">
                    <div className="row ml-4 mt-4">
                        <Box
                            display={"inline-block"}
                            px={1}
                            pb={1}
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    label="Select Company"
                                    variant="filled"
                                    size="small"
                                    value={this.state.selectedCompany}
                                    onChange={this.handleCat}
                                >
                                    {this.state.allProductCompany?.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </TextField></div></Box>

                        <FormControl
                            variant="filled"
                            className="mr-2 mb-2"
                            style={{ minWidth: "150px" }}
                            size="small"
                        >
                            <InputLabel id="demo-simple-select-outlined-label">
                                Product
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                onChange={(e) => {
                                    this.setState({
                                        productId: e.target.value,
                                    });
                                }}
                                name="productId"
                                value={this.state.productId}
                            >
                                {this.renderMenu()}
                            </Select>
                        </FormControl>
                        <TextField
                            id="quantity"
                            label="Quantity"
                            variant="outlined"
                            type="number"
                            className="mr-2"
                            size="small"
                            onChange={(e) =>
                                this.setState({ quantity: e.target.value })
                            }
                        />

                        <TextField
                            id="amount"
                            label="amount"
                            variant="outlined"
                            type="number"
                            className="mr-2"
                            size="small"
                            onChange={(e) =>
                                this.setState({ amount: e.target.value })
                            }
                        />
                        <TextField
                            id="paid"
                            label="paid"
                            variant="outlined"
                            className="mr-2"
                            type="number"
                            size="small"
                            onChange={(e) =>
                                this.setState({ paid: e.target.value })
                            }
                        />
                        <TextField
                            id="expiry_date"
                            label="Expiry Date"
                            variant="outlined"
                            className="mr-2"
                            InputLabelProps={{
                                shrink: true,
                              }}
                            type="date"
                            size="small"
                            onChange={(e) => this.setState({ expiry_date: e.target.value })}
                        />

                        <Button
                            color="primary"
                            variant="contained"
                            className="mb-3"
                            onClick={(e) => this.handleAddSubmit(e)}
                        >
                            <FontAwesomeIcon icon={faPlusCircle} size="2x" />
                        </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            className="mb-3 ml-2"
                            onClick={this.props.refreshLedger}
                        >
                            <FontAwesomeIcon icon={faSyncAlt} size="2x" />
                        </Button>
                    </div>
                </form>
                <ToastContainer />
            </div>
        );
    }
}
