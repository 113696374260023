import React, { Component } from "react";

import {
    TextField,
    Button,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
} from "@material-ui/core";
// import { TabContext, TabList, TabPanel } from "@material-ui/lab";
// import { Row, Col, Card, Badge, Table as Tbl } from "react-bootstrap";
import "./style.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faSyncAlt } from "@fortawesome/free-solid-svg-icons";

import { ToastContainer, toast } from "react-toastify";

//API handling components
import { API_URL } from "./../../global";
import moment from "moment/moment";
const axios = require("axios");

export class AddNewEntry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productId: 0,
            quantity: 0,
            products: null,
            date: this.toDate(),
        };
    }


    toDate = () => (new Date()).toISOString().split('T')[0];

    fetchProducts = () => {
        const query = `SELECT id, name, price, quantity FROM products where status = 1 and type = 1 and quantity > 0`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(API_URL, data)
            .then((res) => {
                let _res = res.data.map((item) => {
                    return [item.name, item.id, item.price, item.quantity];
                });
                this.setState({ products: _res });
            })
            .catch((err) => {
                console.log("product data fetch error: ", err);
            });
    };


    updateProductCount() {
        let query = `SELECT ref from products where id=${this.state.productId}`
        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        axios
            .post(API_URL, data)
            .then((res) => {
                console.log("ref res : > ", res.data[0].ref, this.state.products)
                let cProduct = this.state.products.find(p => p[1] === this.state.productId)
                console.log("found : ", cProduct)
                query = `INSERT into products(name, price, quantity, type) values('${cProduct[0]}', ${cProduct[2]}, ${this.state.quantity}, 2)`
                // query = `INSERT INTO products(name, price, quantity, type) VALUES('${this.state.name}', ${this.state.unitPrice}, ${this.state.quantity}, 1);`;
                let new_ref = res.data[0].ref;
                if (res.data[0].ref) {
                    query = `UPDATE products SET quantity = quantity + ${this.state.quantity} WHERE id=${res.data[0].ref};`;
                }

                data = {
                    crossDomain: true,
                    crossOrigin: true,
                    query: query,
                };
                axios
                    .post(API_URL, data)
                    .then((res) => {
                        console.log("Product count updated successfully : ", res);
                        if (!new_ref) {
                            new_ref = res.data.insertId
                        }
                        query = `UPDATE products SET quantity = quantity - ${this.state.quantity}, ref=${new_ref} WHERE id=${this.state.productId};`
                        data = {
                            crossDomain: true,
                            crossOrigin: true,
                            query: query,
                        };
                        axios
                            .post(API_URL, data)
                            .then((res) => {
                                console.log("Product count updated successfully : ", res);
                                toast.success("Entry added successfully");
                                setTimeout(() => {
                                    this.props.refreshLedger();
                                }, 2000)
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    handleAddSubmit(e) {
        e.preventDefault();
        if (this.state.productId === 0 || this.state.quantity === 0) return

        const query = `INSERT INTO daily(productId, quantity,date) VALUES(${this.state.productId}, ${this.state.quantity}, '${this.state.date}');`;

        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        axios
            .post(API_URL, data)
            .then((res) => {
                this.updateProductCount();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    renderMenu() {
        if (this.state.products != null) {
            return this.state.products.map((product, index) => {
                return <MenuItem value={product[1]} key={index}>{`${product[0]}:${product[3]}`}</MenuItem>;
            });
        }
    }

    componentDidMount() {
        this.fetchProducts();
    }

    render() {
        return (
            <div className="row" style={{ display: "block", margin: 0 }}>
                <form autoComplete="off">
                    <div className="row ml-4 mt-4" style={{ display: "block", margin: 0 }}>
                        <FormControl
                            variant="filled"
                            className="mr-2 mb-2"
                            style={{ minWidth: "150px" }}
                            size="small"
                        >
                            <InputLabel id="demo-simple-select-outlined-label">
                                Product
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                onChange={(e) => {
                                    this.setState({
                                        productId: e.target.value,
                                    });
                                }}
                                name="productId"
                                value={this.state.productId}
                            >
                                {this.renderMenu()}
                            </Select>
                        </FormControl>
                        <TextField
                            id="Date"
                            label="Date"
                            variant="outlined"
                            type="date"
                            className="mr-2"
                            size="small"
                            value={this.state.date}
                            onChange={(e) =>
                                this.setState({ date: e.target.value })
                            }
                        />

                        <TextField
                            id="quantity"
                            label="Quantity"
                            variant="outlined"
                            type="number"
                            className="mr-2"
                            size="small"
                            style={{ width: '7.5em' }}
                            value={this.state.quantity}
                            onChange={(e) =>
                                this.setState({ quantity: e.target.value })
                            }
                        />

                        <Button
                            color="primary"
                            variant="contained"
                            className="mb-3"
                            disabled={this.state.quantity === 0 || this.state.productId === 0}
                            onClick={(e) => this.handleAddSubmit(e)}
                        >
                            <FontAwesomeIcon icon={faPlusCircle} size="2x" />
                        </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            className="mb-3 ml-2"
                            onClick={this.props.refreshLedger}
                        >
                            <FontAwesomeIcon icon={faSyncAlt} size="2x" />
                        </Button>
                    </div>
                </form>
                <ToastContainer />
            </div>
        );
    }
}
