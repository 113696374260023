import React, { Component } from "react";
import "./style.css";
import { Link } from "react-router-dom";
// material UI imports
import {
  Box,
  Button,
  CircularProgress,
  LinearProgress,
} from "@material-ui/core";
import { Row, Col, Badge } from "react-bootstrap";

//API handling components
import { API_URL } from "./../../global";

// datatable setup
import jsZip from "jszip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/free-solid-svg-icons";
window.JSZip = jsZip;
var $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-autofill-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-responsive-bs4");
require("datatables.net-scroller-bs4");
require("datatables.net-select-bs4");
require("pdfmake");

// constants
const axios = require("axios");

export default class PartyList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeRouteId: null,
      activeRouteName: null,
      activePartyId: "",
      activePartyName: "",
      activePartyMobile: "",
      activePartyAddress: "",
      activePartyType: 1,
      partiesData: null,
      isLoading: false,
    };
  }

  fetchPartiesData() {
    let url = API_URL;
    const query = `SELECT * FROM party WHERE city IN (select cityName FROM routeList WHERE routeId=${this.props.routeId}) AND status=1`;
    let data = { crossDomain: true, crossOrigin: true, query: query };
    this.setState({ isLoading: true });
    axios
      .post(url, data)
      .then((res) => {
        console.log("party data: ", res.data);
        this.setState({ partiesData: res.data, isLoading: false });
      })
      .catch((err) => {
        console.log("party data error: ", err);
        this.setState({ isLoading: false });
      });
  }

  componentDidMount() {
    this.fetchPartiesData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.routeId !== this.props.routeId) this.fetchPartiesData();
    const title = `${this.props.routeName} Party list`;
    $("#party_table").DataTable({
      destroy: true,
      dom:
        "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
        "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
        "<'row'<'col-sm-12' tr>>" +
        "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
      buttons: [
        {
          extend: "csv",
          title,
          download: "open",
        },
        {
          extend: "print",
          title,
          messageTop: `<h4 style='text-align:center'>${title}</h4>`,
          download: "open",
        },
      ],
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.routeId !== this.props.routeId) this.forceUpdate();
  }

  renderPartiesData = () => {
    const parties = this.state.partiesData;

    if (parties == null) {
      return null;
    }

    return parties.map((party) => {
      return (
        <tr>
          <td align="center">
            <Badge variant="primary">{party["id"]}</Badge>{" "}
          </td>
          <td align="center">{party["name"]}</td>
          <td align="center">{party["city"]}</td>
          <td align="center">
            <a href={"tel:" + party["mobile"]}>
              <Button className="mx-1" color="primary" variant="secondary">
                {party["mobile"]}
              </Button>
            </a>
          </td>
          <td align="center">{party["balance"] == null ? 0 : party["balance"]}</td>
          <td align="center"> <Link to={`ledgerManager/${party["id"]}`}><Button className="mx-1"
            color="primary"
            variant="contained"><FontAwesomeIcon icon={faBook} /></Button></Link></td>
        </tr>
      );
    });
  };

  render() {
    console.log(this.props.routeId);
    return (
      <Row className="ml-0 mr-0">
        <Col md="12" className="p-0 m-0 measure1">
          <h6>Parties</h6>
          <hr />
          {this.state.isLoading && (
            <Box
              width="100%"
              height="100px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress />
            </Box>
          )}
          {!this.state.isLoading && (
            <div>
              <table
                id="party_table"
                className="display"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr align="center">
                    <th>Party Id</th>
                    <th>Party Name</th>
                    <th>City</th>
                    <th>Mobile No</th>
                    <th>Balance</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{this.renderPartiesData()}</tbody>
              </table>
            </div>
          )}
        </Col>
      </Row>
    );
  }
}
