import React, { useState } from "react";
import { Switch, BrowserRouter as Router, Route, Redirect } from "react-router-dom";

// import CSS styles
import "./App.css";
import "datatables.net-dt/css/jquery.dataTables.min.css";

// import components
import Login from "./components/auth/Login";
import Main from "./components/main_dashboard/Main";
import Biller from "./components/Biller";

// import context
import { WorkerProvider } from "./contexts/workerContext";

function App() {
    const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
    const isLoggedIn2 = localStorage.getItem("isLoggedIn2") === "true";

    return (
        <WorkerProvider>
            <Router>
                <div className="container-fluid m-0 p-0">
                    <Switch>
                        <Route path="/" exact component={Login} />
                        {isLoggedIn && (
                            <Route
                                path="/mainDashboard"
                                exact
                                component={Main}
                            />
                        )}
                        {isLoggedIn2 && (
                            <Route
                                path="/biller"
                                exact
                                component={Biller}
                            />
                        )}
                        {!isLoggedIn && !isLoggedIn2 && (
                            <Redirect to="/" />
                        )}
                    </Switch>
                </div>
            </Router>
        </WorkerProvider>
    );
}

export default App;
