import React, { Component } from "react";

import {
  Button,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Chip,
} from "@material-ui/core";
import { Row, Col, Button as Btn1, Modal, Badge } from "react-bootstrap";

import "./style.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faSyncAlt } from "@fortawesome/free-solid-svg-icons";

import { ToastContainer, toast } from "react-toastify";

//API handling components
import { API_URL } from "./../../global";
const axios = require("axios");

export default class AddNewRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddModal: false,
      routeId: null,
      name: "",
      //   noOfCities: 0,
      cities: [],
      cityOptions: [],
    };
  }

  fetchCityOptions() {
    let url = API_URL;

    const query = `SELECT DISTINCT city FROM party`;
    let data = {
      crossDomain: true,
      crossOrigin: true,
      query: query,
    };
    axios
      .post(url, data)
      .then((res) => {
        console.log("city options data : ", res.data);
        this.setState({ cityOptions: res.data.map((record) => record.city) });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  insertCities() {
    let url = API_URL;
    Promise.all(
      this.state.cities.map((city) => {
        const query = `INSERT INTO routeList(routeId, cityName) VALUES(${this.state.routeId}, '${city}')`;
        let data = {
          crossDomain: true,
          crossOrigin: true,
          query: query,
        };
        return axios.post(url, data);
      })
    )
      .then((res) => {
        console.log("cities added response : ", res);
       window.location.reload()
      })
      .catch((err) => {
        console.log("cities added error : ", err);
      });
  }

  handleAddSubmit(e) {
    e.preventDefault();
    let url = API_URL;

    const query = `INSERT INTO routes(name) VALUES('${this.state.name}')`;
    let data = {
      crossDomain: true,
      crossOrigin: true,
      query: query,
    };
    axios
      .post(url, data)
      .then((res) => {
        toast.success("route created successfully");
        console.log("insert route data : ", res.data);
        this.setState(
          {
            routeId: res.data.insertId,
          },
          this.insertCities
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidMount() {
    this.fetchCityOptions();
  }

  render() {
    return (
      <div>
        <Button
          className="mt-1 mr-1 mb-3"
          color="secondary"
          variant="contained"
          onClick={(e) => {
            this.setState({ showAddModal: true });
          }}
        >
          add new route
        </Button>
        <Modal
          show={this.state.showAddModal}
          onHide={(e) => this.setState({ showAddModal: false })}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add New Route
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form noValidate autoComplete="off">
              <div className="mt-3">
                <Row>
                  <Col size="12">
                    <TextField
                      id="routeName"
                      label="Route name"
                      variant="outlined"
                      className="m-2"
                      defaultValue=""
                      onChange={(e) => {
                        this.setState({
                          name: e.target.value,
                        });
                      }}
                    />
                  </Col>

                  <Col xs={6}>
                    <FormControl
                      variant="outlined"
                      className="mt-2 mb-2 ml-2 pr-3"
                      fullWidth
                    >
                      <InputLabel>Select City</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label={`Select City`}
                        //   defaultValue={city}
                        onChange={(e) =>
                          this.setState({
                            cities: Array.from(
                              new Set([...this.state.cities, e.target.value])
                            ),
                          })
                        }
                      >
                        {this.state.cityOptions.map((city) => (
                          <MenuItem value={city}>{city}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Col>
                  {/* ))} */}
                </Row>
                <Row className="pl-4 pr-4">
                  {this.state.cities.map((city) => (
                    <Chip
                      key={city}
                      label={city}
                      variant="outlined"
                      className="mr-2 mt-2"
                      onDelete={() =>
                        this.setState({
                          cities: this.state.cities.filter(
                            (_city) => city !== _city
                          ),
                        })
                      }
                    />
                  ))}
                </Row>
              </div>
              <hr />
              <div className="mt-2 mr-1">
                <Button
                  style={{ float: "right" }}
                  onClick={(e) => {
                    this.setState({
                      showAddModal: false,
                    });
                    this.handleAddSubmit(e);
                  }}
                >
                  Add
                </Button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <ToastContainer />
      </div>
    );
  }
}
