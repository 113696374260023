import React, { useState } from "react";
import { Row, Col, Navbar } from "react-bootstrap";
import {
    Switch,
    HashRouter as Router,
    Route,
    Redirect,
} from "react-router-dom";
// import CSS styles
import "bootstrap/dist/css/bootstrap.css";

// import components
import Dashboard from "../dashboard/dashboard";
import NavbarPanel from "./NavbarPanel";
import PartyManager from "../party_manager/PartyManager";
import ExpenseManager from "../expense_manager/ExpenseManager";
import StockManager from "../stock_manager/StockManager";
import ProductManager from "../product_manager/ProductManager";
import OrderManager from "../order_manager/orderManager";
import LedgerManager from "../ledger_manager/LedgerManager";
import WorkerManager from "../worker_manager/WorkerManager";
import PresentyManager from "../presenty_manager/PresentyManager";
import VehicleStockManager from "../product_stock_manager/VehicleStockManager";
import RouteManager from "../route_manager/RouteManager";
import daaji from '../../images/daaji.jpg'

function Main(props) {
    const [authenticated, setAuthenticated] = useState(props.location.state);

    function logout() {
        setAuthenticated(null);
    }
    if (authenticated || true) {
        //const { userName } = authenticated;
        const userName = "user1";
        return (
            <Router>
                <div
                    className="grey"
                    style={{ backgroundColor: "#343a40 !important" }}
                >
                    <div className="row">
                        <div className="col-2">
                            <Navbar.Brand
                                id="title"
                                href="#dashboard"
                                className="title rounded-lg border shadow-lg p-2"
                            >
                                <img src={daaji} width={100}/>
                            </Navbar.Brand>
                        </div>
                        <div className="col-10">
                            <NavbarPanel
                                user={userName}
                                logout={() => logout()}
                            />
                        </div>
                    </div>
                </div>
                <div className="container-fluid m-0 p-0">
                    <Row className="m-0 p-0">
                        <Col className="col-sm-12 mt-1 pl-1 pr-1">
                            <Switch>
                                {/* Manager Routes */}
                                <Route exact path="/">
                                    <Redirect to="/mainDashboard" />
                                </Route>
                                <Route
                                    exact
                                    path="/dashboard"
                                    component={Dashboard}
                                />
                                <Route
                                    exact
                                    path="/mainDashboard"
                                    component={OrderManager}
                                />
                                <Route
                                    path="/partyManager"
                                    exact
                                    component={PartyManager}
                                />

                                <Route
                                    path="/stockManager"
                                    exact
                                    component={StockManager}
                                />
                                
                                <Route
                                    path="/VehicleStockManager"
                                    exact
                                    component={VehicleStockManager}
                                />

                                <Route
                                    path="/productManager"
                                    exact
                                    component={ProductManager}
                                />
                                <Route
                                    path="/expenseManager"
                                    exact
                                    component={ExpenseManager}
                                />
                                <Route
                                    path="/ledgerManager/:partyId"
                                    exact
                                    component={LedgerManager}
                                />
                                <Route
                                    path="/workerManager"
                                    exact
                                    component={WorkerManager}
                                />
                                 <Route
                                    path="/routeManager"
                                    exact
                                    component={RouteManager}
                                />
                                <Route
                                    path="/presentyManager/:workerId"
                                    exact
                                    component={PresentyManager}
                                />
                            </Switch>
                        </Col>
                    </Row>
                </div>
            </Router>
        );
    } else {
        return <Redirect to="/" />;
    }
}

export default Main;
