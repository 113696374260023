import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import "./Login.css";

import logo from "../../images/ss.jpg";

const Login = () => {
  const [userName, setUserName] = useState(null);
  const [password, setPassword] = useState(null);
  const history = useHistory();

  let user = { userType: 0, isLoggedIn: false };

  const redirect=() => {
    const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
    const isLoggedIn2 = localStorage.getItem("isLoggedIn2") === "true";
    
    if (isLoggedIn) {
      history.push("/mainDashboard");
      window.location.reload()
      console.log(isLoggedIn)
    } else if (isLoggedIn2) {
      history.push("/biller");
      window.location.reload()
    }
    else{
        history.push("/login");
    }
  }

  const setLogin = () => {
    if (userName === "Daaji" && password === "Master$2809") {
      user = { userType: 1, isLoggedIn: true };
      localStorage.setItem("isLoggedIn", true);
      localStorage.setItem("isLoggedIn2", false);
      
    } else if (userName === "Bill" && password === "Bill@3003") {
      user = { userType: 2, isLoggedIn: true };
      localStorage.setItem("isLoggedIn", false);
      localStorage.setItem("isLoggedIn2", true);
     
    } else {
      toast.error("Invalid username or password");
    }
   redirect();
  };

  return (
    <div className="my_card">
      <div className="Heading">
        {/* <img
          src={logo}
          alt="logo"
          className="img-fluid"
          width={300}
          height={200}
        /> */}
      </div>
      <div className="card_1">
        <div>
          <div className="Wrapper">
            <p className="my_p">Sign in to start your session</p>
            <label htmlFor="inputEmail4" className="form-label"></label>
            <input
              type="text"
              className="form-control my_css"
              id="userName"
              placeholder="User name"
              onChange={(e) => {
                setUserName(e.target.value);
              }}
            />
          </div>
          <div className="">
            <label htmlFor="inputPassword4" className="form-label"></label>
            <input
              type="password"
              className="form-control"
              id="inputPassword4"
              placeholder="Password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
          <div className="flex my_css">
            <div className="">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={setLogin}
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
