import React, { Component } from "react";

//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

// styles
import "./style.css";
import "bootstrap/dist/css/bootstrap.css";

// material UI imports
import {
    TableBody,
    TableContainer,
    Button,
    Paper,
    TextField,
    MenuItem,
} from "@material-ui/core";
import { Row, Col, Button as Btn1, Modal, Badge } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

// Toastify imports
import { toast } from "react-toastify";

// import child components
import { AddNewEntry } from "./AddNewEntry.js";

//API handling components
import { API_URL } from "./../../global";

const axios = require("axios");

export default class ProductManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showProudctUpdateModal: false,
            activeProductId: null,
            activeName: null,
            activeIndPrice: null,
            activeIndQty: null,
            activeExtraPieces: null,
            activeUnitPrice: null,
            activeQuantity: null,
            activeProductForIndividual: 0,
            activeIndividual: null,
            products: null,
            // individualProducts: null,
            // productsToDisplay: "box",

        };
        this.productTableRef = React.createRef();
    }

    fetchProducts = () => {
        const query = `SELECT * FROM products where status=1 and type=1`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(API_URL, data)
            .then((res) => {
                this.setState({ products: res.data }, () => {
                    this.initializeDataTable();
                    console.log(res.data)
                });
            })
            .catch((err) => {
                console.log("data fetch error: ", err);
            });
    };

    handleUpdateSubmit(e) {
        const query = `UPDATE products SET name = '${this.state.activeName}', price = ${this.state.activeUnitPrice}, quantity=${this.state.activeQuantity},individual_unit_price=${this.state.activeIndPrice},individual_qty=${this.state.activeIndQty},extra_pieces=${this.state.activeExtraPieces}	  WHERE id = ${this.state.activeProductId};`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(API_URL, data)
            .then((res) => {
                console.log("update status: ", res.data);
                toast.success("Record Updated successfully");
                window.location.reload();
            })
            .catch((err) => {
                console.log("record update error: ", err);
            });
    }

    deleteProduct(id) {
        const query = `DELETE FROM products WHERE id = ${id};`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(API_URL, data)
            .then((res) => {
                console.log("deleted data: ", res.data);
                console.log("record deleted successfully");
                toast.error("Record deleted successfully");
                window.location.reload();
            })
            .catch((err) => {
                console.log("record delete error: ", err);
            });
    }

    refreshLedger() {
        window.location.reload(false);
    }

    componentDidMount() {
        this.fetchProducts()

    }

    componentDidUpdate() {
        if (this.productTableRef.current) {
            $(this.productTableRef.current).DataTable().destroy();
            this.initializeDataTable();
        }
    }

    initializeDataTable() {
        const title = "Party data -" + moment().format("DD-MMMM-YYYY");

        $(this.productTableRef.current).DataTable({
            destroy: true,
            keys: true,
            dom:
                "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
                "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
                "<'row'<'col-sm-12' tr>>" +
                "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
            buttons: [
                {
                    extend: "csv",
                    title,
                    messageTop: `<h4 style='text-align:center'>${title}</h4>`,
                    download: "open",
                    exportOptions: {
                        columns: [0, 1, 2, 3, 4, 5, 6],
                    },
                },
                {
                    extend: "print",
                    title,
                    messageTop: `<h4 style='text-align:center'>${title}</h4>`,
                    download: "open",
                    exportOptions: {
                        columns: [0, 1, 2, 3, 4, 5, 6],
                    },
                },
            ],
        });
    }

    renderUpdateModal = () => {
        return (
            <Modal
                show={this.state.showProudctUpdateModal}
                onHide={(e) => this.setState({ showProudctUpdateModal: false })}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Update Product
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form noValidate autoComplete="off">
                        <div className="mt-3">
                            <Row>
                                <Col xs={6}>
                                    <TextField
                                        id="name"
                                        label="Product Name"
                                        variant="outlined"
                                        className="m-2"
                                        size="small"
                                        defaultValue={this.state.activeName}
                                        // value={this.state.activeName}
                                        onChange={(e) =>
                                            this.setState({
                                                activeName: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col xs={6}>
                                    <TextField
                                        id="unitPrice"
                                        label="Unit Price"
                                        variant="outlined"
                                        type="number"
                                        defaultValue={
                                            this.state.activeUnitPrice
                                        }
                                        className="m-2"
                                        size="small"
                                        onChange={(e) =>
                                            this.setState({
                                                activeUnitPrice: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col xs={6}>
                                    <TextField
                                        id="quantity"
                                        label="Quantity"
                                        variant="outlined"
                                        type="number"
                                        defaultValue={this.state.activeQuantity}
                                        className="m-2"
                                        size="small"
                                        onChange={(e) =>
                                            this.setState({
                                                activeQuantity: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col xs={6}>
                                    <TextField
                                        id="indUnitPrice"
                                        label="Individual Unit Price"
                                        variant="outlined"
                                        type="number"
                                        defaultValue={this.state.activeIndPrice}
                                        className="m-2"
                                        size="small"
                                        onChange={(e) =>
                                            this.setState({
                                                activeIndPrice: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col xs={6}>
                                    <TextField
                                        id="indQty"
                                        label="Quantity in Individual Box"
                                        variant="outlined"
                                        type="number"
                                        defaultValue={this.state.activeIndQty}
                                        className="m-2"
                                        size="small"
                                        onChange={(e) =>
                                            this.setState({
                                                activeIndQty: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                                <Col xs={6}>
                                    <TextField
                                        id="extraPieces"
                                        label="Extra Pieces"
                                        variant="outlined"
                                        type="number"
                                        defaultValue={this.state.activeExtraPieces}
                                        className="m-2"
                                        size="small"
                                        onChange={(e) =>
                                            this.setState({
                                                activeExtraPieces: e.target.value,
                                            })
                                        }
                                    />
                                </Col>
                            </Row>
                        </div>

                        <div className="mt-2 mr-1">
                            <Button
                                style={{ float: "right" }}
                                onClick={(e) => {
                                    this.setState({
                                        showProudctUpdateModal: false,
                                    });
                                    this.handleUpdateSubmit(e);
                                }}
                                variant="contained"
                                color="primary"
                            >
                                Update
                            </Button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        );
    };

    renderProductData = () => {
        if (this.state.products == null) {
            return null;
        }

        const products = this.state.products;
        let last_modified = null;

        return products.map((record, id) => {
            // extract date only
            let color = record.quantity < 5 ? "red" : "";

            last_modified = moment(record["lastModified"]).format(
                "DD / MM / YYYY HH:MM"
            );

            return (
                <tr key={id}>
                    <td align="center">
                        <Badge variant="primary">
                            {record["id"]}
                            <span hidden>$</span>
                        </Badge>{" "}
                    </td>
                    <td>
                        {record["company"]}
                    </td>
                    <td>{record["name"]}</td>
                    <td align="center">{record["price"]}</td>
                    <td align="center" style={{ color: color }}>{record["quantity"]}</td>
                    <td align="center">{record["individual_unit_price"]}</td>
                    <td align="center">{record["individual_qty"]}</td>
                    <td align="center">{record["extra_pieces"]}</td>
                    <td align="center">{record["remaining_qty"]}</td>
                    <td align="center">{last_modified}</td>
                    <td align="center">
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={(e) =>
                                this.setState({
                                    activeProductId: record.id,
                                    activeName: record.name,
                                    activeUnitPrice: record.price,
                                    activeQuantity: record.quantity,
                                    activeIndPrice: record.individual_unit_price,
                                    activeIndQty: record.individual_qty,
                                    activeExtraPieces: record.extra_pieces,
                                    showProudctUpdateModal: true,
                                })
                            }
                        >
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>
                        {/* delete record */}
                        <Button
                            variant="contained"
                            onClick={(e) => {
                                var pin = window.prompt("Delete the item?");
                                if (pin == 280956) {
                                    this.deleteProduct(record.id);
                                } else {
                                    toast.error("Entered wrong pin");
                                }
                            }}
                        >
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </Button>
                    </td>
                </tr>
            );
        });
    };



    render() {
        return (
            <>

                <div className="container-fluid border m-0 p-1">
                    {this.renderUpdateModal()}
                    <br />
                    <div
                        className="btn-group mb-3"
                        role="group"
                        aria-label="Basic example"
                    >
                        <AddNewEntry refreshLedger={() => this.refreshLedger()} />
                    </div>

                    <Row className="ml-0 mr-0">
                        <Col md="12" className="p-0 m-0 measure1">
                            <TableContainer
                                component={Paper}
                                style={{ maxHeight: "79vh" }}
                            >
                                <table
                                    ref={this.productTableRef}
                                    className="display"
                                    style={{ width: "100%" }}
                                >
                                    <thead>
                                        <tr>
                                            <th align="center">ID</th>
                                            <th>Company</th>
                                            <th align="center">Product Name</th>
                                            <th>Box Price</th>
                                            <th>Box Quantity</th>
                                            <th>individual Units Price</th>
                                            <th>Quantity in individual Box</th>
                                            <th>Extra Pieces</th>
                                            <th>Total Pieces</th>
                                            <th>last modified</th>
                                            <th align="center">Options</th>
                                        </tr>
                                    </thead>
                                    <TableBody>{this.renderProductData()}</TableBody>
                                </table>
                            </TableContainer>
                        </Col>
                    </Row>

                </div>
            </>
        );
    }
}
