import React from "react";
import { Link } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import { Row, Col, Card, Button, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";


import "./style.css";

function NavbarPanel(props) {
    const handleLogout = () => {
        localStorage.clear();
        window.location.reload(false);
    };

    
    return (
        <div >
            <div className="sidebar-dark-primary elevation-4">

                <div  className="mb-2 " >
                    <div className="grey m-0 p-1">
                        <div>
                            <Badge
                                variant="warning"
                                className="mr-1"
                                style={{ textTransform: "capitalize", float: 'right' }}
                            >
                                {props.user}
                                <Button
                                    variant="outline-secondary"
                                    onClick={handleLogout}
                                    size="sm"
                                    className="ml-1"
                                >
                                    <FontAwesomeIcon
                                        icon={faSignOutAlt}
                                    />
                                </Button>
                            </Badge>
                        </div>
                        <ul
                            className="nav nav-pills nav-sidebar nav-treeview"
                            data-widget="treeview"
                            role="menu"
                            data-accordion="false"
                        >
                        <li className="nav-item mr-1">
                                <Link
                                    to="/dashboard"
                                    className="nav-link active"
                                    style={{ marginBottom: '8px', height: '36px', paddingBottom: '23px', paddingTop: '5px' }}
                                >
                                    <p>Dashboard</p>
                                </Link>
                            </li>
                            <li className="nav-item mr-1">
                                <Link
                                    to="/mainDashboard"
                                    className="nav-link "
                                    style={{ marginBottom: '8px', height: '36px', paddingBottom: '23px', paddingTop: '5px' }}
                                >
                                    <p> Order Manager</p>
                                </Link>
                            </li>

                            <li className="nav-item mr-1">
                                <Link
                                    to="/stockManager"
                                    className="nav-link "
                                    style={{ marginBottom: '8px', height: '36px', paddingBottom: '23px', paddingTop: '5px' }}
                                >
                                    <p> Stock Manager</p>
                                </Link>
                            </li>

                            <li className="nav-item mr-1">
                                <Link
                                    to="/VehicleStockManager"
                                    className="nav-link "
                                    style={{ marginBottom: '8px', height: '36px', paddingBottom: '23px', paddingTop: '5px' }}
                                >
                                    <p> Vehicle Stock </p>
                                </Link>
                            </li>


                            <li className="nav-item mr-1">
                                <Link
                                    to="/productManager"
                                    className="nav-link "
                                    style={{ marginBottom: '8px', height: '36px', paddingBottom: '23px', paddingTop: '5px' }}
                                >
                                    <p>  Product Manager</p>
                                </Link>
                            </li>

                            <li className="nav-item mr-1">
                                <Link
                                    to="/expenseManager"
                                    className="nav-link "
                                    style={{ marginBottom: '8px', height: '36px', paddingBottom: '23px', paddingTop: '5px' }}
                                >
                                    <p>  Expense Manager</p>
                                </Link>
                            </li>

                            <li className="nav-item mr-1">
                                <Link
                                    to="/partyManager"
                                    className="nav-link "
                                    style={{ marginBottom: '8px', height: '36px', paddingBottom: '23px', paddingTop: '5px' }}
                                >
                                    <p>  Party Manager</p>
                                </Link>
                            </li>

                            <li className="nav-item mr-1">
                                <Link
                                    to="/workerManager"
                                    className="nav-link "
                                    style={{ marginBottom: '8px', height: '36px', paddingBottom: '23px', paddingTop: '5px' }}
                                >
                                    <p>  Worker Manager</p>
                                </Link>
                            </li>
                            <li className="nav-item mr-1">
                                <Link
                                    to="/routeManager"
                                    className="nav-link "
                                    style={{ marginBottom: '8px', height: '36px', paddingBottom: '23px', paddingTop: '5px' }}
                                >
                                    <p>  Route Manager</p>
                                </Link>
                            </li>
                     
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default NavbarPanel;
