import React, { Component, useRef } from "react";

import BillManager from "./order_manager/BillManager";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";



export default class Biller extends Component {

    handleLogout = () => {
        localStorage.removeItem("isLoggedIn2");
        const { history } = this.props;
        history.push("/");
        window.location.reload();
      };

    
    render() {
        return (<>

            <div className="d-flex" style={{ backgroundColor: "lightgray", padding: "10px", marginBottom: "10px", justifyContent: "space-between" }}>
                <h2>Biller</h2>
                <Button onClick={this.handleLogout} className="btn-danger">Logout</Button>
            </div>

            <BillManager />

        </>
        )
    }
}
