import React, { Component } from "react";

//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

// styles
import "./style.css";
import "bootstrap/dist/css/bootstrap.css";

// material UI imports
import {
    TableBody,
    TableContainer,
    Button,
    Paper,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@material-ui/core";
import { Row, Col, Button as Btn1, Modal, Badge } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

// Toastify imports
import { toast } from "react-toastify";

// import child components
import { AddNewEntry } from "./AddNewEntry";

//API handling components
import { API_URL } from "../../global";
const axios = require("axios");

export default class VehicleStockManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            partyId: 0,
            showAddModal: false,
            showUpdateModal: false,
            showProudctUpdateModal: false,
            activeSupplier: null,
            activeProduct: null,
            activeQuantity: null,
            activeAmount: null,
            activePaid: null,
            activePending: null,
            activeProdutUpdateCount: 0,
            productCount: null,
            daily: null,
            products: null,
            vehicleProducts: null,
            activeBlock: null,
            activeWastage: null
        };
    }

    fetchProducts = () => {
        const query = `SELECT * FROM products where status = 1 and quantity > 0;`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(API_URL, data)
            .then((res) => {
                this.setState({ products: res.data.filter(d => d.type===1), vehicleProducts: res.data.filter(d => d.type===2) });
            })
            .catch((err) => {
                console.log("product data fetch error: ", err);
            });
    };

    fetchDaily = () => {
        const query = `SELECT p.name, d.* FROM daily d left join products p on p.id = d.productId and p.type=2`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(API_URL, data)
            .then((res) => {
                this.setState({ daily: res.data });
                this.initializeDataTable();
            })
            .catch((err) => {
                console.log("ledger data fetch error: ", err);
            });
    };

    handleUpdateSubmit(e) {
        let url = API_URL;

        const query = `UPDATE daily SET quantity = ${this.state.activeBlock} WHERE id=${this.state.activeRecordId};`;
        let data = {
            crossDomain: true,
            crossOrigin: true,
            query: query,
        };
        axios
            .post(url, data)
            .then((res) => {
                toast.success("Record details updated successfully");
                this.fetchDaily();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    reduceProductCount(productId, quantity) {
        const query = `UPDATE products SET quantity = quantity - ${quantity}  WHERE id = ${productId};`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(API_URL, data)
            .then((res) => {
                console.log("count update status data: ", res.data);
                console.log("count updated successfully");
                setTimeout(() => {
                    this.refreshLedger();
                }, 2000);
            })
            .catch((err) => {
                console.log("record delete error: ", err);
            });
    }

    deleteRecord(id, productId, quantity) {
        const query = `UPDATE stockledger SET status = 0  WHERE id = ${id};`;
        let data = { crossDomain: true, crossOrigin: true, query: query };
        axios
            .post(API_URL, data)
            .then((res) => {
                console.log("deleted status data: ", res.data);
                console.log("record deleted successfully");
                toast.error("Record deleted successfully");
                this.reduceProductCount(productId, quantity);
            })
            .catch((err) => {
                console.log("record delete error: ", err);
            });
    }

    refreshLedger() {
        window.location.reload(false);
    }

    componentDidMount() {
        this.fetchDaily();
        this.fetchProducts();
    }

    initializeDataTable() {
        const title = "Party data -" + moment().format("DD-MMMM-YYYY");

        $("#ledger_table").DataTable({
            destroy: true,
            keys: true,
            dom:
              "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
              "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
              "<'row'<'col-sm-12' tr>>" +
              "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
            buttons: [
              {
                extend: "csv",
                title,
                messageTop: `<h4 style='text-align:center'>${title}</h4>`,
                download: "open",
                exportOptions: {
                  columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
                },
              },
              {
                extend: "print",
                title,
                messageTop: `<h4 style='text-align:center'>${title}</h4>`,
                download: "open",
                exportOptions: {
                  columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
                },
              },
            ],
        });
    }

    renderProduction = () => {
        if (this.state.daily == null) {
            return null;
        }

        const ledger = this.state.daily;
        let last_modified = null;

        return ledger.map((record, id) => {
            // extract date only
            last_modified = moment(record["last_modified"]).format(
                "DD / MM / YYYY HH:MM"
            );

            return (
                <tr key={id}>
                    <td align="center">
                        <Badge variant="primary">
                            {record["id"]}
                            <span hidden>$</span>
                        </Badge>{" "}
                    </td>
                    <td>{moment(record["date"]).format("YYYY/MM/DD")}</td>
                    <td>{record["name"]}</td>
                    <td>{record["quantity"]}</td>
                    <td>{moment(record["last_modified"]).format("DD/MM/YYYY HH:MM")}</td>
                    <td align="center">
                        <Button
                            color="secondary"
                            variant="contained"
                            className="mr-2"
                            onClick={(e) => {
                                this.setState({
                                    activeRecordId: record.id,
                                    activeBlock: record.quantity,
                                    activeWastage: record.wastage
                                });
                                this.setState({ showUpdateModal: true });
                            }}
                        >
                            <FontAwesomeIcon icon={faPenAlt} />
                        </Button>
                        <Modal
                            show={this.state.showUpdateModal}
                            onHide={(e) =>
                                this.setState({ showUpdateModal: false })
                            }
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    Update Entry
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form noValidate autoComplete="off">
                                    <div className="mt-8">
                                        <Row>
                                            <Col>
                                                <TextField
                                                    id="quantity"
                                                    label="Quantity"
                                                    variant="outlined"
                                                    className="m-2"
                                                    defaultValue={
                                                        this.state.activeBlock
                                                    }
                                                    onChange={(e) =>
                                                        this.setState({
                                                            activeBlock:
                                                                e.target.value,
                                                        })
                                                    }
                                                />
                                                <Btn1
                                                    className="mt-3"
                                                    onClick={(e) => {
                                                        this.setState({
                                                            showUpdateModal: false,
                                                        });
                                                        this.handleUpdateSubmit(
                                                            e
                                                        );
                                                    }}
                                                >
                                                    Update
                                                </Btn1>
                                            </Col>
                                        </Row>
                                    </div>
                                </form>
                            </Modal.Body>
                        </Modal>
                    </td>
                </tr>
            );
        });
    };

    renderProductCount = () => {
        if (!this.state.products || this.state.products.length < 1) return null;

        return this.state.vehicleProducts.map((product, index) => {
            return (
                <Button
                    key={index}
                    color={product.quantity < 20 ? "secondary" : "primary"}
                    variant="outlined"
                    className="float-left mb-2 mr-1"
                    size="small"
                >
                    <h6>
                        {product.name}:&nbsp; &nbsp;
                        <b>{product.quantity}</b>
                    </h6>
                </Button>
            );
        });
    };

    render() {
        return (
            <div className="container-fluid border m-0 p-1">
                <div
                    style={{
                        maxHeight: "190px",
                        padding: "20px",
                        overflow: "auto",
                        border: "1px solid #0002"
                    }}
                >
                    <Row>{this.renderProductCount()}</Row>
                </div>
                <br />
                <div
                    className="btn-group mb-3"
                    role="group"
                    aria-label="Basic example"
                    style={{display: "block"}}
                >
                    <AddNewEntry refreshLedger={() => this.refreshLedger()} />
                </div>

                <Row className="ml-0 mr-0">
                    <Col md="12" className="p-0 m-0 measure1">
                        <TableContainer
                            component={Paper}
                            style={{ maxHeight: "79vh" }}
                        >
                            <table
                                id="ledger_table"
                                className="display"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th align="center">ID</th>
                                        <th>date</th>
                                        <th>Product</th>
                                        <th>Quantity</th>
                                        <th>last modified</th>
                                        <th align="center">Options</th>
                                    </tr>
                                </thead>
                                <TableBody>
                                    {this.renderProduction()}
                                </TableBody>
                            </table>
                        </TableContainer>
                    </Col>
                </Row>
            </div>
        );
    }
}
